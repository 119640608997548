import React from "react"
import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

const Download = ({data}) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className="both-edges flex justify-between flex-wrap downloads">
        <div className="w-1/4">
          <h2 className="text-xl mb-16">{t("downloads.first")}</h2>
          {data.aboutForks.nodes
            .map(pdf => {
              return (
                <div className="mb-4"><a
                  href={pdf.publicURL}
                  target="_blank"
                  rel="noreferrer">
                    <StaticImage src="../images/icon_pdf.png" alt="PDF" className="w-1/3 align-middle"/>
                    <p className="inline-block mb-0 px-2 align-middle w-2/3">{pdf.name}</p>
                  </a>
                </div>
              )
          })      
          }
        </div>
        <div className="w-1/4">
          <h2 className="text-xl mb-16">{t("downloads.second")}</h2>
          {data.safetyInspection.nodes
            .map(pdf => {
              return (
                <div className="mb-4"><a
                  href={pdf.publicURL}
                  target="_blank"
                  rel="noreferrer">
                    <StaticImage src="../images/icon_pdf.png" alt="PDF" className="w-1/3 align-middle"/>
                    <p className="inline-block mb-0 px-2 align-middle w-2/3">{pdf.name}</p>
                  </a>
                </div>
              )
          })      
          }
        </div>
        <div className="w-1/4">
          <h2 className="text-xl mb-16">{t("downloads.third")}</h2>
          {data.orderForms.nodes
            .map(pdf => {
              return (
                <div className="mb-4"><a
                  href={pdf.publicURL}
                  target="_blank"
                  rel="noreferrer">
                    <StaticImage src="../images/icon_pdf.png" alt="PDF" className="w-1/3 align-middle"/>
                    <p className="inline-block mb-0 px-2 align-middle w-2/3">{pdf.name}</p>
                  </a>
                </div>
              )
          })      
          }
        </div>
        <div className="w-1/4">
          <h2 className="text-xl mb-16">{t("downloads.fourth")}</h2>
          {data.salesMarketing.nodes
            .map(pdf => {
              return (
                <div className="mb-4"><a
                  href={pdf.publicURL}
                  target="_blank"
                  rel="noreferrer">
                    <StaticImage src="../images/icon_pdf.png" alt="PDF" className="w-1/3 align-middle"/>
                    <p className="inline-block mb-0 px-2 align-middle w-2/3">{pdf.name}</p>
                  </a>
                </div>
              )
          })      
          }
        </div>
      </div>


    </Layout>
  )
}

export default Download

export const pageQuery = graphql`

query($lang: String) {

  aboutForks: allFile(
    filter: { relativeDirectory: {eq: "about-forks"}, sourceInstanceName: {eq: $lang}, extension: {eq: "pdf"} }
  ) {
    nodes {
      publicURL
      name
    }
  }

  orderForms: allFile(
    filter: { relativeDirectory: {eq: "order-forms"}, sourceInstanceName: {eq: $lang}, extension: {eq: "pdf"} }
  ) {
    nodes {
      publicURL
      name
    }
  }

  safetyInspection: allFile(
    filter: { relativeDirectory: {eq: "safety-inspection"}, sourceInstanceName: {eq: $lang}, extension: {eq: "pdf"} }
  ) {
    nodes {
      publicURL
      name
    }
  }

  salesMarketing: allFile(
    filter: { relativeDirectory: {eq: "sales-marketing"}, sourceInstanceName: {eq: $lang}, extension: {eq: "pdf"} }
  ) {
    nodes {
      publicURL
      name
    }
  }

}

`;